import Alerts from './alerts'
import Locale from './locale'

var shoppingCart = (function($) {

    'use strict';
	function init() {
		minusClicked();
		plusClicked();
		checkIfEmptyCart();
		checkIfNumber();
		setShoppingCartCounter();
		bindAddProductEvent();
		bindUpdateQuantityEvent();
		bindRemoveProductEvent();
		recalculateCart();
	}

	function checkIfNumber() {
		$(".js-update-shopping-cart-item.cart-input").on('change', function(event) {
			var currentValue = parseInt($(this).val()); //var
	
			// check if input is number
			if (isNaN(currentValue)) {
				$(this).val(1);
			} else {
				if(currentValue > 200) {
					$(this).val(200);
				}
	
				if(currentValue < 1 ) {
					$(this).val(1);
				}
			}
		});		
	}

	function minusClicked() {
		$('.fa-minus').on('click', function() {
			let input = $(this).next();
			if (input.val() > 1) {
				input.val(+input.val() - 1);
				input.trigger('change');
			}
		})
	}

	function plusClicked() {
		$('.fa-plus').on('click', function() {

			let input = $(this).prev();
			//if (input.val() < 200) {
				input.val(+input.val() + 1);
				input.trigger('change');
			//}
		})
	}

	function checkIfEmptyCart() {
		if ($('.js-shopping-cart-item-container').length) {
			$('.js-summary-container').removeClass('display-none').addClass('display-block');
		} else {
			$('.js-summary-container').removeClass('display-block').addClass('display-none');
			$('.js-no-cart-items-message').removeClass('display-none').addClass('display-block');
			$('.shopping-cart-container').addClass('display-none');
		}
	}

	function recalculateCart() {

		var cartTotalPrice = 0;
		var currency = ' лв.'
		if ($('.js-shopping-cart-item-container').length) {
			$('.js-shopping-cart-item-container').each(function() {
				var thisContainer = $(this);
				var thisContainerPrice = parseFloat(thisContainer.find('.product-price').text());
				var thisContainerQuant = thisContainer.find('.cart-input').val();
	
				// console.log('==========');
				// console.log('thisContainerPrice ' + thisContainerPrice);
				// console.log('thisContainerQuant ' + thisContainerQuant);
				// console.log('==========');
	
				var calculatedTotalRowPrice = thisContainerPrice * thisContainerQuant;
	
				thisContainer.find('.row-price').text(calculatedTotalRowPrice.toFixed(2) + currency); //+ currency);
	
				cartTotalPrice = cartTotalPrice + calculatedTotalRowPrice;
			});
		}

		$('.total-price').text(cartTotalPrice.toFixed(2) + currency);
	}

	function bindAddProductEvent(){

		$('.js-add-to-shopping-cart').on('click', function(e){
			let productId = $(this).data('product-id');
			let url = $(this).data('url');

			$.ajax({
			   type: "POST",
			   url: url,
			   data: {
			   	product_id: productId,
			   	quantity: 1
			   },
			   dataType: "json"
			 }).done(function(json){
			    console.log(json.message)

			    if(json.message != undefined){
			    	Alerts.showAlert(json.message, 'success', true);
			    }

				checkIfEmptyCart();

			    updateShoppingCartCounter(json.products_count);

			 }).fail(function(xhr, status, error) { 
			 	let errors = ['Провалена заявка'];

			 	try {
				  errors = $.parseJSON(xhr.responseText).errors; 
				} catch (error) {
				  console.error("Error while parsing errors response")
				  console.error(error);
				}

				Alerts.clearAlerts();
				errors.forEach(e => Alerts.showAlert(e, 'error'));
			 }); 
		})
	}

	function bindUpdateQuantityEvent(){
		$('.js-update-shopping-cart-item').on('change', function(){
			let quantity = $(this).val();
			let productId = $(this).data('product-id');
			let url = $(this).data('url');

			$.ajax({
			   type: "POST",
			   url: url,
			   data: {
			   	product_id: productId,
			   	quantity: quantity
			   },
			   dataType: "json"
			 }).done(function(json){
			    console.log(json.message)

			    if(json.message != undefined){
			    	Alerts.showAlert(json.message, 'success', true);
			    }

				recalculateCart();

			    updateShoppingCartCounter(json.products_count);

			 }).fail(function(xhr, status, error) { 
			 	let response = xhr.responseJSON || { errors: ['Провалена заявка'] };

			 	if(response.quantity != undefined) {
					$(".js-update-shopping-cart-item.cart-input").val(response.quantity);
			 	}else{
			 		setTimeout(function() { 
				       location.reload();
				    }, 800);
			 	}

				Alerts.clearAlerts();
				response.errors.forEach(e => Alerts.showAlert(e, 'fail'));
			 }); 
		})
	}

	function bindRemoveProductEvent(){
		$('.js-delete-shopping-cart-item').on('click', function(){

			let productId = $(this).data('product-id');
			let url = $(this).data('url');

			$.ajax({
			   type: "DELETE",
			   url: url,
			   data: {
			   	product_id: productId
			   },
			   dataType: "json"
			 }).done(function(json){
			    console.log(json.message)

			    if(json.message != undefined){
			    	Alerts.showAlert(json.message, 'success', true);
			    }

				
			    updateShoppingCartCounter(json.products_count);

				//console.log('.js-shopping-cart-item-container[data-product-id="' + productId + '"]');
			    $('.js-shopping-cart-item-container[data-product-id="' + productId + '"]').remove();
				
				checkIfEmptyCart();
				recalculateCart();

			 }).fail(function(xhr, status, error) { 
			 	let errors = ['Провалена заявка'];

			 	try {
				  errors = $.parseJSON(xhr.responseText).errors; 
				} catch (error) {
				  console.error("Error while parsing errors response")
				  console.error(error);
				}

				Alerts.clearAlerts();
				errors.forEach(e => Alerts.showAlert(e, 'fail'));
			 }); 			 
		})
	}


	function setShoppingCartCounter(){
		let locale = Locale.getLocale();
		let url = '/' + locale + '/shopping-cart/counter';
		$.ajax({
			   type: "GET",
			   url: url,
			   dataType: "json"
			 }).done(function(json){

			    updateShoppingCartCounter(json.products_count);

			 }).fail(function(xhr, status, error) { 
			 	console.log(xhr.responseText);
			 }); 
	}

	function updateShoppingCartCounter(counter){
		let displayText = '';
		const counterEl = $('.js-shopping-cart-counter');

		if (counter > 99){
			displayText = '99+';
		} else if (counter > 0) {
			displayText = counter;
			$('.count').show();
		} else {
			$('.count').hide();
		}
		
		counterEl.html(displayText);

		if(counter > 0){
			counterEl.parent().removeClass('display-none');
		}else{
			counterEl.parent().addClass('display-none');
		}
	}

	return {
    	init: init
  	};

	  
})(jQuery);

export default shoppingCart;